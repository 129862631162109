.radius-10 {
  border-radius: 10px;
}
.orderplc-pg-mb-30 {
  margin-bottom: 30px;
}
/* .orderplc-pg-inner-wrap  */
.conatct .orderplc-pg-success-main-wrap  {
  background: var(--color-green) !important;
}
.yt-order-placed-icn {
  max-height: 135px;
  width: auto;
}
/* .orderplc-wrap  */
.head .orderplc-ttll {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-white) !important;
}
/* .orderplc-wrap  */
.head .orderplc-text1 {
  font-size: 16px;
  line-height: 1.13;
  /* color: white !important; */
  color: var(--color-white) !important;
}
.orderplc-pg-success-main-wrap .orderplc-btn {
  opacity: 0.99;
  border-radius: 6px;
  /* background-color: var(--button-primary) !important; */
  background-color: var(--color-ButtonBackRegular);
  border-color: var(--button-primary) !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  /* color: #ffffff; */
  color: var(--color-ButtonTextRegular);
  width: 100%;
  max-width: 180px;
}
.orderplc-pg-success-main-wrap .orderplc-btn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.orderplc-pg-inner-wrap {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1900px) {
  .orderplc-pg-mb-80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 1280px) {
  .orderplc-pg-inner-wrap {
    margin-top: 60px !important;
    margin-bottom: 60px;
  }
}
/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .orderplc-pg-inner-wrap {
    margin-top: 20px !important;
    margin-bottom: 60px;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .orderplc-pg-inner-wrap {
    margin-top: 15px !important;
    margin-bottom: 15px;
    padding: 20px !important;
  }
  .orderplc-pg-success-main-wrap {
    padding-bottom: 0 !important;
  }
  .yt-order-placed-icn {
    max-height: 85px;
  }
  .orderplc-ttl {
    font-size: 16px;
    line-height: 1.13;
  }
  .orderplc-text {
    line-height: 1.14;
    font-size: 14px;
  }
  .orderplc-btn {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
  }
}
