
#pagination > .pagination {
  justify-content: center;
      background-color: #242724;
      padding: 15px 0px;
      color: var(--color-primary);
  }

  #pagination > .pagination > li{
    padding: 0px 10px;
  }
  #pagination > ul.pagination {
    margin-bottom: 0px;
  }
  #pagination > .pagination > .active{
    border-bottom: 1px solid var(--color-primary);
    padding: 0px 10px;
}