/*
* Add Address Modal CSs
*/
.select-addr-title-bar span {
  position: absolute;
  left: 0;
  /* right: 0; */
  right: 6%;
  text-align: center;
  margin: 0 !important;
  top: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.select-addr-body-wrap .form-control {
  height: auto;
  border: none;
  border-bottom: 2px solid #f6f8fa;
  box-shadow: none;
  padding-left: 0;
}
.select-addr -btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.select-addr-body-wrap .pp-sa-all-addres-list li {
  border-radius: 10px;
  border: solid 2px #cae2fe;
}
.select-addr-body-wrap .pp-sa-all-addres-list li.active {
  border-radius: 10px;
  border: solid 2px #15aa71;
}
.select-addr-body-wrap .pp-na-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.select-addr-body-wrap .pp-na-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.select-addr-body-wrap .pp-no-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.select-addr-body-wrap .pp-sa-list-none {
  list-style: none;
}
.select-addr-body-wrap .pp-sa-type {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.select-addr-body-wrap .pp-sa-address {
  font-size: 16px !important;
  line-height: 1.15 !important;
  color: var(--color-dark-grey) !important;
  text-align: left;
  font-weight: bold;
}
.radius-10 {
  border-radius: 10px;
  position: relative;
}
.select-addr-body-wrap .profile-pg-mb-30 {
  margin-bottom: 30px;
}
.select-addr-body-wrap .pp-sa-all-addres-list li:last-child {
  margin-bottom: 40px;
}
.yt-cm-sadd-body {
  padding-top: 40px !important;
}
.pp-sa-all-addres-list li {
  margin-bottom: 20px;
}
.pp-sa-all-addres-list li {
  cursor: pointer;
}
.select-addr-modal-btn {
  border-radius: 6px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  /* color: #ffffff; */
  color: var(--color-ButtonTextRegular);
}
.select-addr-modal-btn:hover {
  color: var(--color-ButtonTextHover);
}
.select-addr-body-wrap .profile-pg-inner-wrapper {
  min-height: 420px;
}
.select-add-bg-blue {
  /* background-color: var(--color-dark-black); */
  background-color: var(--color-ButtonBackRegular);
}
.select-add-bg-blue:hover {
  background-color: var(--color-ButtonBackHover);
}
.select-add-bg-black {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mr-2.select-add-bg-blue.btn-block {
  /* background: var(--color-primary); */
  background-color: var(--color-ButtonBackRegular);
}
button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mr-2.select-add-bg-blue.btn-block:hover {
  background-color: var(--color-ButtonBackHover);
}

button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mt-0.select-add-bg-black.btn-block {
  /* background: var(--color-primary); */
  background-color: var(--color-ButtonBackRegular);
}
button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mt-0.select-add-bg-black.btn-block:hover {
  background-color: var(--color-ButtonBackHover);
}
/*
* Add Address Modal CSs
*/
.add-addr-title-bar {
  border-color: #dbdbdb;
  border-width: 2px;
}
.add-addr-title-bar span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.ad-addr-body-wrap .form-control {
  height: auto;
  border: none;
  border-bottom: 2px solid #dbdbdb;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-black);
}
.ad-addr-body-wrap .profile-form-tag {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.add-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
  margin-top: 30px;
}
.profile-form-field.error .profile-form-tag {
  color: var(--color-orange);
}
.profile-form-field.error .form-control {
  border-color: var(--color-orange);
  color: var(--color-orange);
}
.invalid_error {
  color: var(--color-orange);
}
.fields-active.error {
  padding-top: 5px;
  display: block;
}
@media (min-width: 1366.8px) {
  .select-addr-body-wrap .profile-pg-inner-wrapper {
    min-height: 500px;
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .select-addr-modal-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-pg-inner-wrap {
    padding: 20px !important;
  }
  .yt-cm-sadd-row.row .yt-cm-sadd-col:first-child {
    padding-right: 10px;
    max-width: 13%;
    -ms-flex: 0 0 13%;
    flex: 0 0 13%;
  }
  .yt-cm-sadd-row.row .yt-cm-sadd-col:last-child {
    padding-left: 10px;
    padding-right: 0;
  }
  .yt-cm-sadd-row.row .yt-sadd-ttl-chek-img {
    margin-bottom: 6px !important;
  }
  .yt-cm-sadd-row.row .pp-sa-delet.text-right img {
    width: 29px;
  }

  .ad-addr-body-wrap .cm-edit-add-col {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }
  .ad-addr-body-wrap {
    padding: 8px 0;
  }
  .ad-addr-body-wrap .form-group {
    margin-bottom: 30px;
  }
  .ad-addr-body-wrap .add-addr-btn {
    margin-top: 0;
    font-size: 16px;
    line-height: 1.13;
  }
  .fields-active.error {
    font-size: 14px;
    line-height: 1.14;
  }
}
@media (max-width: 767.98px) {
  /* .pp-sa-info-wrap {
    margin: 20px 0;
  } */
  .yt-sadd-ttl-chek-img {
    margin-bottom: 5px !important;
  }
  .pp-sa-delet img {
    margin-bottom: 0 !important;
  }
  .yt-cm-sadd-body.modal-body {
    padding: 14px 10px !important;
  }
  .yt-cm-sadd-col:last-child {
    max-width: 80%;
    padding-left: 7.5px;
  }
  .yt-cm-sadd-col:first-child {
    width: 20%;
    padding-right: 7.5px;
  }
  .pp-sa-img-wrap img {
    max-width: 34px;
    height: auto;
  }
  .select-addr-body-wrap .pp-sa-type {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  .select-addr-body-wrap .pp-sa-address {
    font-size: 14px;
    line-height: 1.14;
    text-align: left;
  }
  /* .select-addr-modal-btn.select-add-bg-blue {
    display: none;
  } */
  .yt-mb-add-new-address-nbtn {
    display: block !important;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    color: var(--color-black);
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-add-modal-body {
    padding: 30px 40px 20px !important;
  }
  .ad-addr-body-wrap .profile-form-tag {
    font-size: 14px;
    line-height: 1.14;
  }
  .ad-addr-body-wrap .form-control {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px;
    line-height: 1.11;
  }
  .add-addr-btn {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: normal;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
  .add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }
  .ad-addr-body-wrap .profile-form-tag {
    font-size: 13px;
    line-height: 1.15;
  }
  .ad-addr-body-wrap .form-control {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 17px;
    line-height: 1.12;
  }
  .ad-addr-body-wrap .yt-fp-form .form-group {
    margin-bottom: 20px !important;
  }
  .yt-add-modal-body {
    padding: 20px 15px !important;
  }
  .add-addr-btn {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}
