@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* regular */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimPro-Regular.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* light */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* medium */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimPro-Medium.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

/* thin */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url('GTWalsheimPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



