/*Order Detail css*/
.order-page-prevpage {
    color: var(--color-black) !important;
    font-weight: 500 !important;
  }
  
  .order-page-prevpage:hover {
    color: var(--color-sky-blue) !important;
    font-weight: bold !important;
  }
  
  .hc-breadcrumbs {
    color: var(--color-dark-grey);
    font-size: 12px;
    line-height: 1.17;
  }
  
  .order-details-main-wrapper {
    padding: 40px 40px 42px;
  }
  
  .hc-current {
    color: var(--color-black);
    font-size: 12px;
    font-weight: bold !important;
    line-height: 1.17;
  }
  
  .hc-mb-30 {
    margin-bottom: 30px;
  }
  
  .hc-mb-80 {
    margin-bottom: 80px;
  }
  
  .radius-10 {
    border-radius: 10px;
  }
  
  .hc-beckfrom-ct-page {
    color: var(--color-black);
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    cursor: pointer;
    display: inline-block;
  }
  
  .hcp-back-arrow {
    cursor: pointer;
  }
  
  .order-tracing-details {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-blue);
    text-decoration: underline;
  }
  .order-total-price {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  
  .order-details-status {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-black);
    position: absolute;
    bottom: 20px;
    text-align: right;
    right: 0;
  }
  
  .order-details-sub-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    color: var(--color-black);
  }
  
  .order-details-status-bar {
    border-top: 2px solid #dbdbdb;
    border-bottom: 2px solid #dbdbdb;
  }
  
  .order-step-1 .order-status-date {
    color: var(--color-dark-grey);
    padding-left: 10px;
  }
  
  .order-step-1 h4,
  .order-details-address-type {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    color: var(--color-black);
  }
  
  .order-details-address-details {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-black);
  }
  
  .order-details-status-bar .order-details-status-wrap {
    border-left: 2px solid #dbdbdb;
  }
  
  .order-details-status-bar .order-details-status-wrap li {
    position: relative;
    padding-left: 10px;
  }
  
  .order-details-status-icn {
    position: absolute;
    left: -17px;
    top: 0;
  }
  
  .order-details-contact {
    font-size: 15px;
    line-height: 1.2;
    color: var(--color-blue);
  }
  
  .order-details-message {
    font-size: 16px;
    line-height: 1.19;
    color: var(--color-dark-grey);
  }
  
  .order-details-status.on-the-way::after {
    content: "";
    width: 7px;
    height: 7px;
    background: var(--color-green);
    border-radius: 100%;
    display: block;
    position: absolute;
    bottom: 3px;
    left: -15px;
  }
  
  .pp-order-product-ttl {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-black);
  }
  
  .order-ul-list-none {
    list-style: none;
  }
  
  .op-order-quantity {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    color: var(--color-dark-grey);
  }
  
  .op-order-product-price1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 1.08px;
    color: var(--color-green);
  }
  
  .order-prodict-type-container {
    max-width: 59vw;
    overflow-x: auto;
  }
  
  /* width */
  .order-prodict-type-container::-webkit-scrollbar {
    height: 5px;
  }
  
  /* Track */
  .order-prodict-type-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .order-prodict-type-container::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .order-prodict-type-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .order-prodict-type th,
  .order-prodict-type td {
    padding: 3px;
  }
  
  .order-prodict-type th {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
    text-transform: uppercase;
  }
  
  .order-prodict-type td {
    font-size: 17px;
    line-height: 1.24;
    color: var(--color-black);
  }
  
  .od-product-img {
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    max-width: 133px;
    width: 133px;
    height: 133px;
  }
  .od-product-img img {
    width: 117px;
    height: 117px;
    object-fit: cover;
    object-position: top;
  }
  
  .order-number-wrap .order-tag,
  .order-date-wrap .order-tag {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    color: var(--color-dark-grey);
  }
  
  .order-number-wrap .order-tag-val,
  .order-date-wrap .order-tag-val {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: var(--color-black);
  }
  
  .order-bdr-between {
    width: 2px;
    background-color: #f6f8fa;
    margin: 0 15px;
    height: 18px;
  }
  
  .order-write-review {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: right;
    color: var(--color-blue);
    text-decoration: underline;
  }
  
  .order-cancel-btn {
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.69px;
    text-align: left;
    color: var(--color-orange);
  }
  
  .pp-od-no-ttl {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  
  .pp-od-no-text.mb-0 {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey);
  }
  
  .pp-no-order-btn {
    opacity: 0.99;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    border-color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: #ffffff;
  }
  
  .order-product-info {
    padding-left: 40px;
  }
  
  .od-single-prd-details-wrap {
    position: relative;
  }
  
  .order-product-quanity {
    position: absolute;
    right: 0;
    top: 30px;
  }
  
  .order-details-main-wrapper .sinlge-mo-dt-wrap {
    padding-left: 173px;
  }
  
  @media (min-width: 1280px) {
    .od-cm-col-pad {
      padding: 0 40px;
    }
  }
  
  @media (min-width: 1900px) {
    .od-cm-col-pad {
      padding: 0 20px;
    }
  }
  
  @media (min-width: 1366px) and (max-width: 1900px) {
    .order-details-main-wrapper {
      padding: 35px 30px;
    }
    .hc-beckfrom-ct-page {
      font-size: 26px;
    }
    .order-details-sub-title {
      margin-bottom: 15px;
    }
    .order-details-status.on-the-way::after {
      bottom: 6px;
    }
    .order-details-status-bar {
      margin-top: 0 !important;
    }
    .order-prodict-type th:first-child,
    .order-prodict-type td:first-child {
      padding: 3px !important;
    }
  
    .order-prodict-type th,
    .order-prodict-type td {
      padding-left: 70px !important;
    }
    .pp-order-product-ttl {
      line-height: 1.25;
    }
    .od-single-prd-details-wrap .od-product-img.p-1 {
      -ms-flex-item-align: start;
      align-self: flex-start;
    }
    .order-product-info {
      padding-left: 20px;
    }
    .order-details-main-wrapper .sinlge-mo-dt-wrap {
      padding-left: 115px;
    }
    .order-number-wrap .order-tag,
    .order-date-wrap .order-tag,
    .order-number-wrap .order-tag-val,
    .order-date-wrap .order-tag-val {
      font-size: 14px;
      line-height: 1.36;
    }
    .order-write-review {
      font-size: 16px;
      line-height: 1.19;
    }
    .order-prodict-type th {
      font-size: 12px;
      line-height: 1.17;
      letter-spacing: 0.46px;
    }
    .order-prodict-type td {
      font-size: 16px;
      line-height: 1.31;
    }
    .od-product-img {
      width: 95px;
      height: 95px;
    }
    .od-product-img img {
      width: 72px;
      height: 72px;
    }
  }
  /*
    * Screen for under 1280 Resolution 
    */
  
  @media (max-width: 1279.92px) and (min-width: 768px) {
    .od-product-img {
      width: 70px;
      height: 70px;
    }
    .od-product-img img {
      width: 57px;
      height: 57px;
    }
    .od-single-prd-details-wrap {
      margin-bottom: 0 !important;
      padding-bottom: 5px !important;
      padding-top: 28px !important;
    }
  }
  
  @media (max-width: 1279.92px) {
    .hc-beckfrom-ct-page {
      margin-bottom: 10px;
    }
    .order-tracing-details {
      font-size: 14px;
      line-height: 1.14;
    }
    .order-total-price {
      font-size: 14px;
      line-height: 1.14;
    }
    .order-details-sub-title {
      font-size: 18px;
      line-height: 1.11;
      margin: 5px 0 17px !important;
    }
    .order-details-address-details {
      color: var(--color-dark-grey);
      font-size: 14px;
      line-height: 1.36;
    }
    .order-details-main-wrapper {
      padding: 23px 20px 20px;
    }
    .order-details-main-wrapper .sinlge-mo-dt-wrap {
      padding-left: 80px;
    }
    .od-product-img {
      max-width: 70px;
      -ms-flex-item-align: start;
      align-self: flex-start;
    }
    .order-product-info {
      padding-left: 10px;
    }
    .order-number-wrap .order-tag,
    .order-date-wrap .order-tag,
    .order-number-wrap .order-tag-val,
    .order-date-wrap .order-tag-val {
      font-size: 12px;
      line-height: 1.58;
    }
    .order-write-review {
      padding: 0;
      font-size: 14px;
      line-height: 1.36;
    }
    .pp-order-product-ttl {
      font-size: 16px;
      line-height: 1.13;
    }
    .order-prodict-type th {
      font-size: 12px;
      line-height: 1.17;
      letter-spacing: 0.46px;
    }
    .order-prodict-type td {
      font-size: 16px;
      line-height: 1.31;
    }
    .order-product-price {
      font-size: 20px;
      line-height: 1.15;
      letter-spacing: 0.77px;
    }
    .order-details-contact {
      font-size: 14px;
      line-height: 1.14;
    }
    .order-cancel-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.54px;
    }
  }
  /*
    * Screen for under 768 Resolution 
    */
  
  @media (max-width: 767.92px) {
    .order-details-main-wrapper {
      padding: 15px;
      position: relative;
      margin: 67px 0 !important;
      padding-bottom: 55px;
    }
    .order-details-main-wrapper .sinlge-mo-dt-wrap {
      padding: 0;
      max-width: 60%;
    }
    .order-bdr-between {
      display: none;
    }
    .order-write-review {
      font-size: 14px;
      line-height: 1.36;
      padding: 0;
      text-decoration: none;
    }
    .order-number-wrap .order-tag,
    .order-date-wrap .order-tag {
      font-size: 12px;
      line-height: 1.58;
    }
    .order-number-wrap .order-tag-val,
    .order-date-wrap .order-tag-val {
      font-size: 12px;
      line-height: 1.58;
    }
    .yt-sp-my-order-tdet-wrap {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      margin: 10px 0px;
    }
    /* .od-product-img {
        max-width: 70px;
        -ms-flex-item-align: start;
        align-self: flex-start;
      } */
    .od-product-img {
      width: 70px;
      height: 70px;
    }
    .od-product-img img {
      width: 57px;
      height: 57px;
    }
    .order-product-info {
      padding-left: 14px;
    }
    .pp-order-product-ttl {
      font-size: 14px;
      line-height: 1.36;
      max-width: 160px;
    }
    .order-product-quanity {
      top: 15px;
    }
    .order-prodict-type th {
      font-size: 10px;
      line-height: 1.1;
    }
    .order-prodict-type td {
      font-size: 14px;
      line-height: 1.5;
    }
    .op-order-product-price1 {
      width: 100%;
      padding-right: 0 !important;
      text-align: right;
    }
    .order-tracing-details {
      font-size: 12px;
      line-height: 1.17;
    }
    .order-total-price {
      font-size: 12px;
      line-height: 1.17;
    }
    .order-details-status {
      left: 100px;
      right: auto;
      bottom: 40px;
    }
    .order-details-status-bar {
      margin: 0 !important;
    }
    .order-details-sub-title {
      font-size: 14px;
      line-height: 1.36;
      margin-bottom: 26px;
      margin-top: 0;
    }
    .order-details-shipping-barmy-3 {
      padding-top: 15px;
    }
    .order-details-shipping-barmy-3 .order-details-address-type,
    .order-details-shipping-barmy-3 .order-details-address-details {
      font-size: 15px;
      line-height: 1.2;
    }
    .order-cancel-wrap {
      width: 100%;
      position: absolute;
      left: 15px;
      right: 0;
      bottom: -40px;
      text-align: left !important;
      padding: 0 !important;
    }
    .order-cancel-wrap .order-cancel-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.54px;
    }
    .op-order-product-price1 {
      font-size: 15px;
      line-height: 1.2;
    }
    .op-order-product-price1 {
      position: absolute;
      right: 0;
      bottom: 40px;
    }
    .order-product-info .order-ul-list-none {
      padding-top: 25px !important;
    }
    .order-product-quanity .order-ul-list-none,
    .order-details-status-bar .order-ul-list-none {
      padding-top: 0 !important;
    }
  }
  